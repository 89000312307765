<template>
  <div>
    <IbClientTabs />
    <b-overlay
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-row>
              <b-col
                cols="12"
                md="4"
                lg="3"
              >
                <b-form-group
                  :label="$t('client_id')"
                  label-for="filter-client_id"
                >
                  <b-form-input
                    id="filter-client_id"
                    v-model="filters.client_id"
                    type="text"
                    :placeholder="$t('client_id')"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
                lg="3"
              >
                <b-form-group
                  :label="$t('client_account')"
                  label-for="filter-client_account"
                >
                  <b-form-input
                    id="filter-client_account"
                    v-model="filters.client_account"
                    type="text"
                    :placeholder="$t('client_account')"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
                lg="3"
              >
                <b-form-group
                  :label="$t('amount_from')"
                  label-for="filter-amount-from"
                >
                  <b-form-input
                    id="filter-amount-from"
                    v-model="filters.amount_from"
                    type="text"
                    :placeholder="$t('amount_from')"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
                lg="3"
              >
                <b-form-group
                  :label="$t('amount_to')"
                  label-for="filter-enter-amount-to"
                >
                  <b-form-input
                    id="filter-enter-amount-to"
                    v-model="filters.amount_to"
                    type="text"
                    :placeholder="$t('amount_to')"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
                lg="3"
              >
                <b-form-group
                  class="flex-shrink-0"
                  :label="$t('from_time_execution')"
                >
                  <div class="d-flex">
                    <flat-pickr
                      ref="from-expiration-date"
                      v-model="filters.date_from"
                      class="form-control"
                      name="from-expiration"
                      :placeholder="$t('from_time_execution')"
                      autocomplete="nope"
                    />
                    <b-button @click="clearFromDate()">
                      <feather-icon
                        icon="XIcon"
                        class=""
                        size="15"
                      />
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
                lg="3"
              >
                <b-form-group
                  class="flex-shrink-0"
                  :label="$t('to_time_execution')"
                >
                  <div class="d-flex">
                    <flat-pickr
                      ref="to-expiration-date"
                      v-model="filters.date_to"
                      class="form-control"
                      name="to-expiration"
                      :placeholder="$t('to_time_execution')"
                      autocomplete="nope"
                      clearable
                    />
                    <b-button @click="clearToDate()">
                      <feather-icon
                        icon="XIcon"
                        class=""
                        size="15"
                      />
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
                lg="3"
              >
                <b-form-group
                  :label="$t('forex.platform')"
                  label-for="platform"
                >
                  <v-select
                    id="platform"
                    v-model="filters.platform"
                    :options="platforms"
                    label="title"
                    :clearable="true"
                    :placeholder="$t('select-platform')"
                    :return-object="false"
                    :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
                lg="3"
              >
                <b-form-group
                  :label="$t('type')"
                  label-for="platform"
                >
                  <v-select
                    id="platform"
                    v-model="filters.type"
                    :options="types"
                    label="title"
                    :clearable="true"
                    :placeholder="$t('type')"
                    :return-object="false"
                    :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
                lg="12"
              >
                <b-button
                  class="w-100 mx-auto my-2"
                  variant="success"
                  @click="search()"
                >
                  {{ $t('search') }}
                </b-button>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-table
                  sticky-header="1000px"
                  no-border-collapse
                  responsive
                  striped
                  hover
                  :fields="membersfields"
                  :items="items"
                >
                  <template #cell(user)="data">
                    <span>{{ data.item.client_user.first_name + ' ' + data.item.client_user.last_name }}</span>
                  </template>
                  <template #cell(date)="data">
                    <span>{{ getFormattedDate(data.item["date"]) }}</span>
                  </template>
                  <template
                    slot="bottom-row"
                    slot-scope="data"
                  >
                    <td />
                    <td />
                    <td>
                      <span class="font-weight-bolder">{{ $t('total-amount') }}: {{ total_amount }}</span>
                    </td>

                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row align-h="center">
              <b-col cols="auto">
                <b-pagination
                  v-if="pagination.total > pagination.per_page"
                  v-model="pagination.page"
                  :total-rows="pagination.total"
                  :per-page="pagination.per_page"
                  align="fill"
                  size="sm"
                  class="mt-2 mb-1"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import IbClientTabs from '../components/ibClientTabs.vue'
import PannelIbApis from '@/modules/IB/services/apis'
import moment from '@/libs/moment'

const IB = new PannelIbApis()
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    IbClientTabs,
    flatPickr,
    vSelect,
  },

  data() {
    return {
      items: [],
      show: false,
      pagination: {
        page: 1,
        per_page: 30,
        total: 0,
      },
      filters: {
        client_id: '',
        client_account: '',
        amount_from: '',
        amount_to: '',
        comment: '',
        date_from: '',
        date_to: '',
        platform: '',
        type: '',
      },
      platforms: [
        { title: 'metatrader 4', value: 'mt4' },
        { title: 'metatrader 5', value: 'mt5' },
      ],
      types: [
        { title: this.$t('rebate'), value: 'rebate' },
        { title: this.$t('deposit'), value: 'deposit' },
      ],
      total_amount: '',
    }
  },
  computed: {
    membersfields() {
      return [
        { key: 'user', label: this.$t('user') },
        { key: 'account', label: this.$t('account-id') },
        { key: 'amount', label: this.$t('amount') },
        { key: 'type', label: this.$t('type') },
        { key: 'comment', label: this.$t('comment') },
        { key: 'date', label: this.$t('date') },
      ]
    },
    page() {
      return this.pagination.page
    },
  },
  watch: {
    page(val) {
      if (val) {
        this.getData()
      }
    },
  },
  async mounted() {
    await this.getData()
  },
  methods: {
    async getData() {
      this.show = true
      const data = {
        ...this.filters,

        ...this.pagination,
        client_id: this.$route.params.userID,
      }
      await IB.transferHistory(data).then(res => {
        this.items = res.data.results.data
        if (res.data.results.data[0]) {
          this.total_amount = res.data.results.data[0].total_amount.toFixed(2)
        }
        this.pagination.total = res.data.results.pagination.total
      }).finally(() => {
        this.show = false
      })
    },
    async search() {
      this.pagination.page = 1
      this.getData()
    },
    getFormattedDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    clearFromDate() {
      const datePickerWrap = this.$refs['from-expiration-date']
      if (!datePickerWrap) {
        return
      }
      datePickerWrap.fp.clear()
    },
    clearToDate() {
      const datePickerWrap = this.$refs['to-expiration-date']
      if (!datePickerWrap) {
        return
      }
      datePickerWrap.fp.clear()
    },
  },

}
</script>
